import { useRouter } from "next/router";
import * as en from "../dictionaries/en.json";
import * as es from "../dictionaries/es.json";
import * as vi from "../dictionaries/vi.json";
import * as zh_Hans from "../dictionaries/zh_Hans.json";

export const dictionaries = {
  en,
  es,
  vi,
  zh_Hans,
};

export type Locale = "en" | "es" | "vi" | "zh_Hans";

export default function useDictionary() {
  const router = useRouter();
  const locale: Locale = router.locale?.match(/en|es|vi|zh_Hans/)
    ? (router.locale as Locale)
    : "en";

  return dictionaries[locale || "en"];
}
