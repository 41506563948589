import { GraphQLClient, ClientError } from "graphql-request";
import { graphql } from "../__generated__/gql";

export const graphqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_API_BASE}/graphql`,
  {
    credentials: "include",
    mode: "cors",
  },
);

const WhoamiBaseDocument = graphql(`
  query WhoamiBase {
    whoami {
      id
      firstName
      lastName
      emailAddress
    }
  }
`);

export async function getMyBaseInfo(): Promise<{
  id: string;
  emailAddress: string;
} | null> {
  const r1 = await graphqlClient.request(WhoamiBaseDocument);
  return r1.whoami || null;
}

export function errorToStringUnfiltered(e: unknown): string {
  if (e instanceof ClientError)
    return (
      e.response.errors?.[0]?.message ??
      `GraphQL Error (Code: ${e.response.status})`
    );
  if (e instanceof Error) return e.message;
  if (
    typeof e === "object" &&
    e &&
    "message" in e &&
    typeof e.message === "string"
  )
    return e.message;
  return "Unknown Error";
}

export function errorToString(e: unknown): string {
  const errorString = errorToStringUnfiltered(e);
  if (errorString.toLowerCase().includes("econnreset"))
    return "Please retry soon (Connection Reset)";
  if (errorString.toLowerCase().includes("network error"))
    return "Please retry soon (Network Error)";
  if (errorString.toLowerCase().includes("failed to fetch"))
    return "Please retry soon (Failed to Fetch)";
  return errorString;
}

export function isNotNullOrUndefined<T>(input: T): input is NonNullable<T> {
  return input !== null && input !== undefined;
}
